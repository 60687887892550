import { API_URL } from "../../../../primeagile.config"
import { clearCart } from "../../../../redux/slices/pages"
import https from "../../../../services/https"

export const postUser = ({
  userCart,
  formdata,
  item,
  dispatch,
  gatewayId,
  setIsLoading,
}) => {
  let orderItemWList = []

  for (let [key, value] of Object.entries(userCart)) {
    console.log({ key, value })
    let fprice = value.price
    orderItemWList = [
      ...orderItemWList,
      {
        pageId: value.pageId,
        pageUrl: value.url,
        description: value.title,
        price: parseFloat(fprice)?.toFixed(2),
        quantity: value.quantity,
        itemLabel: value.variation,
        image: value.image,
        shippingPrice: value.shipping,
        jsonData: {},
      },
    ]
  }
  const data = {
    // entityId: 0,
    entityW: {
      isPerson: true,
      objectDataDefinitionId: 14,
      firstName: formdata?.name?.split(" ")[0],
      lastName: formdata?.name?.split(" ")[1],
      email: formdata.email,
      contactMethodWList: [
        {
          contactMethod: 4,
          contactMethodDetails: formdata.email,
        },
        {
          contactMethod: 2,
          contactMethodDetails: formdata.phone,
        },
      ],
      jsonData: {},
      entityAddress: [
        {
          address1: formdata?.addressone,
          address2: formdata?.addresstwo,
          city: formdata?.city,
          state: formdata?.state,
          zip: formdata?.zip,
          country: formdata?.country,
        },
      ],
      active: true,
    },
    paymentW: {
      confirmationCode: item.paymentIntent.id,
      amount: item.paymentIntent.amount,
      gatewayId: Number(gatewayId),
      status: item.paymentIntent.status,
      // merchantApprovalCode: "string",
      channel: item.paymentIntent.payment_method,
      jsonData: {},
    },
    orderItemWList,
    jsonData: {
      merchantType: "Stripe",
      email: formdata.email,
      gatewayId: Number(gatewayId),
      paymentDate: new Date(),
    },
    orderNotificationEmails: [formdata.email],
    status: "NEW",
  }


  const link = `${API_URL}/open/commerce/order?client=${process.env.GATSBY_BUILDSITE}`
  https(
    link,
    "post",
    data,
    undefined,
    res => {
      setTimeout(() => {
        dispatch(clearCart())
        setIsLoading(false)
        window.location.href = "/thank-you/"
      }, 1000)
    },
    error => {
      setTimeout(() => {
        dispatch(clearCart())
        setIsLoading(false)
        window.location.href = "/"
      }, 1000)
    }
  )
}
